<template>
  <div>
    <div class="_Tab">
      <span
        v-for="(item, index) in ['我的任务', '发布文件任务', '发布专题任务']"
        :key="index"
        :class="{ activeColor: index == activatIndex }"
        @click="clickTabItem(index)"
      >{{ item }}</span>
    </div>
    <div class="_SelectHeader select-box">
      <div class="_Select">
        <span style="width: 100px">任务状态：</span>
        <el-select v-model="company" clearable placeholder="请选择">
          <el-option
            v-for="item in companyList"
            :key="item.id"
            :label="item.companyName"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div class="_Select">
        <span style="width: 100px">完成时间：</span>
        <el-date-picker v-model="value" type="date" placeholder="选择日期"></el-date-picker>
      </div>
      <el-button icon="el-icon-search"></el-button>
      <div class="assign" v-show="activatIndex != 0" @click="studyBtn(activatIndex)">
        <img src="../../assets/images/study.png" alt />
        <span>指派学习</span>
      </div>
      <div class="assign" v-show="activatIndex != 0" @click="examBtn(activatIndex)">
        <img src="../../assets/images/exam.png" alt />
        <span>指派业务测评</span>
      </div>
    </div>
    <div class="_TableBox">
      <el-table
        :data="tableData"
        ref="multipleTable"
        tooltip-effect="dark"
        style="width: 100%"
        :height="elementHeight"
        :stripe="true"
      >
        <el-table-column type="selection" width="50" />
        <el-table-column label="公司" prop="companyName" />
        <el-table-column label="部门" prop="dept" />
        <el-table-column label="岗位" prop="post" />
        <el-table-column label="考核人员" prop="assess" />
        <el-table-column label="任务类型" prop="task" />
        <el-table-column label="发布人" prop="issuer" />
        <el-table-column label="任务时间" prop="missionTime" />
        <el-table-column label="任务状态" prop="taskStatus" />
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <div class="spanBox">
              <span v-if="scope.row.taskStatus == '已完成'" class="study">去学习</span>
              <span v-else class="exam">去业务测评</span>
              <span class="redeploy" @click="redeploy">转派</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="_Pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
      ></el-pagination>
    </div>
    <fileDialog 
     title='指派学习'
    :form='form'
    :dialogVisible='studyDialog'
    @cancel='cancel'
    @confirm='confirm'
    >
    </fileDialog>
    <fileDialog 
     title='指派业务测评'
    :form='form'
    :dialogVisible='examDialog'
    @cancel='cancel'
    @confirm='confirm'>
    </fileDialog>
    <fileDialog 
     title='指派学习'
    :form='form'
    :specialDialogVisible='specialStudyDialog'
    @cancel='cancel'
    @confirm='confirm'
    >
    </fileDialog>
    <fileDialog 
     title='指派业务测评'
    :form='form'
    :specialDialogVisible='specialExamDialog'
    @cancel='cancel'
    @confirm='confirm'>
    </fileDialog>

    <el-dialog
      title="转派任务"
      :visible.sync="redeployDialog"
      width="495px"
      >
      <el-form :model="form" label-width="auto">
        <el-form-item label="转派对象">
          <el-select v-model="form.company" clearable placeholder="请选择" style="width:100%">
            <el-option
              v-for="item in companyList"
              :key="item.id"
              :label="item.companyName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import fileDialog from "@/components/dialog/fileDialog";
export default {
  components: {
    fileDialog,
  },
  data() {
    return {
      activatIndex: 0,
      company: "",
      value: "",
      companyList: [],
      elementHeight: 0,
      tableData: [
        {
          companyName: "公司1",
          dept: "部门1",
          post: "岗位1",
          assess: "考核人1",
          task: "任务1",
          issuer: "发布人1",
          missionTime: "2023/06/12",
          taskStatus: "已完成",
        },
        {
          companyName: "公司2",
          dept: "部门2",
          post: "岗位2",
          assess: "考核人2",
          task: "任务2",
          issuer: "发布人2",
          missionTime: "2023/06/12",
          taskStatus: "未完成",
        },
      ],
      currentPage: 1,
      studyDialog: false,
      examDialog:false,
      specialStudyDialog: false,
      specialExamDialog:false,
      redeployDialog:false,
      form: {},
    };
  },
  mounted() {
    this.getElementHeight();
  },
  methods: {
    clickTabItem(i) {
      this.activatIndex = i;
      this.getElementHeight();
    },
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        if (this.activatIndex == 0) {
          this.elementHeight = clientHeight - (60 + 40 + 70 + 66); //70是div底部距离底部的高度
        } else {
          this.elementHeight = clientHeight - (60 + 40 + 70 + 92); //70是div底部距离底部的高度
        }
      });
    },
    handleSizeChange() {},
    handleCurrentChange() {},
    // 转派
    redeploy(){
      this.redeployDialog = true;
    },
    studyBtn(e) {
      if(e == 1){
        this.studyDialog = true;
      }else{
        this.specialStudyDialog = true
      }
    },
    examBtn(e){
      if(e == 1){
        this.examDialog = true;
      }else{
        this.specialExamDialog = true
      }
    },
    cancel(v){
      this.studyDialog = v
      this.examDialog = v
      this.specialStudyDialog = v
      this.specialExamDialog = v
    },
    confirm(v){
      this.studyDialog = v
      this.examDialog = v
      this.specialStudyDialog = v
      this.specialExamDialog = v
    }
  },
};
</script>

<style lang="less" scoped>
._Tab {
  color: #a09f9f;
  margin-bottom: 15px;
  padding-bottom: 10px;
}

._Tab .activeColor {
  border-bottom: 2px solid #2f98ff;
  color: black;
}

._Tab span {
  padding: 10px 0;
}
.assign {
  margin-left: 8px;
  width: 63px;
  height: 56px;
  background: #f2f8ff;
  border: 1px solid #cdd2dc;
  border-radius: 8px;
  display: flex;
  align-self: flex-end;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    width: 21px;
    height: 21px;
  }
  span {
    margin-top: 4px;
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #646870;
  }
}
.spanBox {
  span {
    display: inline-block;
    width: 73px;
    height: 30px;
    text-align: center;
    line-height: 30px;
  }
  .study {
    background: #bef4cd;
  }
  .redeploy {
    background: #dfeafe;
    margin-left: 10px;
  }
  .exam {
    background: #f4bebe;
  }
}
</style>