<template>
  <div>
    <el-dialog :title="title" :visible.sync="newDialogVisible" width="495px" :before-close="cancel">
      <el-form ref="form" :model="form" label-width="auto">
        <el-form-item label="任务类型">{{title == '指派学习'?'文件学习-指派学习':'文件学习-指派业务测评'}}</el-form-item>
        <el-form-item label="所属公司">
          <el-select v-model="form.company" clearable placeholder="请选择">
            <el-option
              v-for="item in companyList"
              :key="item.id"
              :label="item.companyName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属部门">
          <el-select v-model="form.dept" clearable placeholder="请选择">
            <el-option
              v-for="item in companyList"
              :key="item.id"
              :label="item.companyName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属岗位">
          <el-select v-model="form.post" clearable placeholder="请选择">
            <el-option
              v-for="item in companyList"
              :key="item.id"
              :label="item.companyName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考核人员">
          <el-select v-model="form.assess" clearable placeholder="请选择">
            <el-option
              v-for="item in companyList"
              :key="item.id"
              :label="item.companyName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务完成时间">
          <el-date-picker v-model="form.time" type="date" placeholder="选择日期"></el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消 新 增</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :title="title" :visible.sync="newSpecialDialogVisible" width="495px" :before-close="cancel">
      <el-form ref="form" :model="form" label-width="auto">
        <el-form-item label="任务类型">{{title == '指派学习'?'专题学习-指派学习':'专题学习-指派业务测评'}}</el-form-item>
        <el-form-item label="专题列表">
          <el-select v-model="form.company" clearable placeholder="请选择">
            <el-option
              v-for="item in companyList"
              :key="item.id"
              :label="item.companyName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考核人员">
          <el-select v-model="form.assess" clearable placeholder="请选择">
            <el-option
              v-for="item in companyList"
              :key="item.id"
              :label="item.companyName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务完成时间">
          <el-date-picker v-model="form.time" type="date" placeholder="选择日期"></el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消 新 增</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
    props:['title','form','dialogVisible','specialDialogVisible'],
    data(){
        return{
            companyList:[],
            newDialogVisible:null,
            newSpecialDialogVisible:null,
        }
    },
    watch:{
        dialogVisible(val){
            this.newDialogVisible = val
        },
        specialDialogVisible(val){
            this.newSpecialDialogVisible = val
        }
    },
    methods:{
        cancel(){
            this.$emit('cancel',false)
        },
        confirm(){
            this.$emit('confirm',false)
        }
    }
};
</script>

<style lang="less" scoped>
.el-form-item {
  margin-bottom: 0 !important;
}
.el-select,
.el-date-editor.el-input {
  width: 100% !important;
}
</style>